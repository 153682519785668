@import url("https://fonts.googleapis.com/css?family=Inter");

.background {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  z-index: 1;
  display: inline-block;
  margin: auto;
  padding-top: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 130px;
  color: #ffffff;
  text-shadow: 8px 0px 0px #000000;
  margin-bottom: 450px;
}

.bio {
  position: absolute;
  display: inline-block;
  width: 274px;
  height: 78px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-transform: lowercase;
  color: #ffffff;
  margin: auto;
  margin-top: 170px;
}

.name {
  display: flex;
  justify-content: center;
}

.button1,
.button2,
.button3 {
  position: absolute;
  width: 100px;
  height: 38px;
  background: #f3f3f3;
  box-shadow: 2px 2px 0px 1px #000000;
  border-radius: 33px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: black;
  cursor: pointer;
}

.button1 {
  margin-left: 125px;
}

.button2 {
}

.button3 {
  margin-left: -125px;
}

.pow {
  background-color: black;
  width: 250px;
  display: inline-flex;
  height: 290px;
  position: absolute;
  margin-top: 400px;
  border-radius: 20px;
}

.container {
  position: absolute;
  margin-top: 330px;
  display: flex;
  width: 1100px;
  height: 275px;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.project1,
.project2,
.project3 {
  display: flex;
}

.swiper-slide-shadow {
  background: none !important;
  box-shadow: none !important;
}

.skillscontainer {
  position: absolute;
  margin-top: 450px;
}

.myskills {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
}

.myskills1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;

  color: #ffffff;
}

.myskills:hover {
  font-size: 38px;
  cursor: pointer;
}

.myskills1:hover {
  font-size: 38px;
}

.carousel {
  position: absolute;
  height: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 185px;
  width: 300px;
  overflow: hidden;

}

.items {

  position: relative;
  width: 400px;
  height: 100px;
  perspective: 1000px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: white;
  display: contents;
  }

.item {
  position: absolute;
  top: 0;
  text-decoration: none;
}

.up-button{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-bottom: 70px;
    background: none;
    border: none;
}

.down-button{
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 400px;
  background: none;
  border: none;
}

ion-icon{
  color: white;
  font-size: 64px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .heading{
      font-size: 80px;
      text-shadow: 3px 0px 0px #000000;
    }

    .bio{
      font-size: 24px;
      width: 184px;
      line-height: normal;
      margin-top: 121px;
    }

    .buttonnavbar{
      margin-bottom: 400px !important;

    } 

    .button1{
      margin-left: 90px;
    }

    .button3{
      margin-left: -90px;
    }
    
    .button1, .button2, .button3{
      width: 77px;
      height: 32px;
      font-size: 12px;

    }

    .container{
      width: 500px !important;
      height: 130px !important;
      margin-right: 165px;
      margin-top: 270px ;
    }

    .gradientanim, .moresoon, .taketherapy {
      width: 330px !important;
    }

    .buttonnavbar{
      margin-top: 234px !important;
      margin-left: -90px !important;
    }

    .taketherapy{
      height: 315px !important;
    }

    .moresoon{
      height: 235px !important;
    }
     
    .mainhead{
      margin-top: 75px !important;
    }
  }

    @media only screen and (max-height: 585px){

      .name{
        margin-top: 120px !important; 
      }

      .buttonnavbar{
        margin-top: 277px !important;
      }

      .heading{
      font-size: 60px;
      text-shadow: 3px 0x 0x #000000 !important;
      }

      .bio{
        font-size: 16px;
        width: 124px;
        margin-top: 93px;
      }

      .button1, .button2, .button3{
        width: 52px;
        height: 26px;
        font-size: 9px;
      }

      .button1 {
        margin-left: 66px;
    }

      .button3 {
      margin-left: -66px;
  }

    .buttonnavbar {
    margin-left: -48px !important;
  
  }

  .taketherapy{
    width: 220px !important;
  }

  .gradientanim{
    width: 220px !important;
  }

  .moresoon{
    width: 220px !important;
  }

  .container{
    margin-top: 140px;
    margin-right: 100px;
    max-width: 370px !important;
  }

  .mainhead {
    margin-top: 0px !important;
}

  .up-button{
    width: 33px;
    margin-bottom: 80px;
  }

  .down-button{
    width: 33px;
    margin-top: 254px;

  }

  .carousel{
    margin-top: 85px;
  }

  .items{
    font-size: 24px;
  }
}

  @media screen and (max-height: 440px) {


    .buttonnavbar{
      margin-top: 355px !important;
      
    }

    .name{
      margin-top: 220px !important;
    }

    .container{
      margin-top: 180px;
    }
  }
    

